.glide__arrow{
    position: absolute;
    display:block;
    top:50%;
    z-index:2;
    color:rgb(51, 51, 51);
    text-transform:uppercase;
    padding:9px 12px;
    background-color:transparent;
    border:2px solid rgba(255, 255, 255, 0.87);
    border-radius:4px;
    box-shadow:0 0.25em 0.5em 0 rgba(0,0,0,0.1);
    text-shadow:0 0.25em 0.5em rgba(0,0,0,0.1);
    opacity:1;
    cursor:pointer;
    transition:opacity 150ms ease, border 300ms ease-in-out;
    transform:translateY(-50%);
    line-height:1}
.glide__arrow:focus{
    outline:none
}
.glide__arrow:hover{
    border-color:rgb(58, 245, 214)}
.glide__arrow--left{
    left:2rem
}
.glide__arrow--right{
    right:2rem
}
.glide__arrow--disabled{
    opacity:0.33
}
.glide__bullets{
    position:absolute;
    z-index:2;
    bottom:2em;
    left:50%;
    display:inline-flex;
    list-style:none;
    transform:translateX(-50%)
}
.glide__bullet{
    background-color:rgba(255,255,255,0.5);
    width:24px;
    height:24px;
    padding:0;
    border-radius:50%;
    border:2px solid transparent;
    transition:all 300ms ease-in-out;
    cursor:pointer;
    line-height:0;
    box-shadow:0 0.25em 0.5em 0 rgba(0,0,0,0.1);
    margin:0 0.25em
}
.glide__bullet:focus{
    outline:none
}
.glide__bullet:hover,.glide__bullet:focus{
    border:2px solid rgb(255, 255, 255);
    background-color:rgba(255,255,255,0.5)
}
.glide__bullet--active{
    background-color:white
}
.glide--swipeable{
    cursor:grab;
    cursor:-moz-grab;
    cursor:-webkit-grab
}
.glide--dragging{
    cursor:grabbing;
    cursor:-moz-grabbing;
    cursor:-webkit-grabbing
}